<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex flex-column mb-2">
            <div>
              <div class="d-flex align-center">
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="shadow-off" small icon
                      @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
                  </template>
                  <span>Back to Ticket list</span>
                </v-tooltip>
                <v-btn icon small @click="refreshData()">
                  <v-icon>mdi-refresh</v-icon></v-btn>
                <div class="ml-4" v-if="queryDetails._id">
                  <v-chip v-if="queryDetails.stage === 0" small label class="Tabblue--text" color="lightblue">Open</v-chip>
                  <v-chip v-else small label color="lightgreen" class="teal--text">Closed</v-chip>
                </div>
              </div>
            </div>
            <div class="align-center d-flex" v-if="queryDetails._id && !loading">
              <div>
                <div class="d-flex align-center">
                  <div class="subtitle-1 font-weight-bold mr-2">
                    {{ queryDetails.issue || {} }}
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <div>
                  <span class="mr-2 mt-3 mb-3"><v-icon class="mr-1">mdi-calendar-range</v-icon> Request date:
                  </span>
                  <span class="mr-1">
                    {{ $nova.formatDate(queryDetails.created_at) }}</span>
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex align-center" v-if="$route.params.QueryHelpDeskId">
                <v-fade-transition leave-absolute>
                  <v-progress-circular v-if="assUserloading" size="18" color="Primary" indeterminate :width="1"
                    class="mr-2"></v-progress-circular>
                </v-fade-transition>
                <lb-dropdown :disabled="queryDetails.stage == 2 ? '' : disabled" label="Assign User" v-model="assignUser"
                  :items="userList" itemtext="name" width="250px" itemvalue="_id" hidedetails @change="addAssignUser()" />
                <v-chip class="ml-2 Tabblue--text" color="lightblue" small v-if="$nova.hasRight(right, 'support')">
                  Send To Close
                </v-chip>
                <v-chip class="ml-2 teal--text" color="lightgreen" small
                  v-if="queryDetails.stage === 0 && $nova.hasRight(right, 'support')" @click="closeQuery()">
                  Close
                </v-chip>
                <v-chip class="ml-2 orange--text" small color="lightorange" v-else @click="openQuery()">
                  Re Open</v-chip>
              </div>
            </div>
            <div v-if="queryDetails._id" class="overflow-scroll" style="max-height: 78vh; overflow:scroll">
              <div v-for="(item, index) in conversationList" :key="index"  >
                <div color="rgba(0,0,0,0)" flat class="mb-3 d-flex justify-end"
                  v-if="item.user_id_detail._id == $store.state.user.id">
                  <div class="text-right line-1">
                    <v-card elevation="8" dark class="ml-2 d-inline-block rounded-lg rounded-br-0 outlined"
                      max-width="500px" color="blue">
                      <v-card-text class="py-1 px-3">
                        <div class="white--text">{{ item.value }}</div>
                      </v-card-text>
                    </v-card>

                    <div class="timeFont mt-1">
                      {{ $nova.formatDate(item.created_at, "DD MMM yyyy h:mm A") }}
                    </div>
                  </div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar class="ml-2 mb-4" size="30" elevation="10" v-bind="attrs" v-on="on" :color="
                        item.user_id_detail._id == $store.state.user.id
                          ? 'indigo'
                          : 'red'
                      ">
                        <span class="white--text">{{
                          item.user_id_detail.name[0]
                        }}</span>
                      </v-avatar>
                    </template>
                    <span>{{ item.user_id_detail.name }}</span>
                  </v-tooltip>
                </div>

                <div color="rgba(0,0,0,0)" flat class="d-flex mb-3" v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar dark class="" size="30" elevation="10" v-bind="attrs" v-on="on" :color="
                        item.user_id_detail._id == $store.state.user.id
                          ? 'indigo'
                          : 'red'
                      ">
                        <span class="white--text">{{
                          item.user_id_detail.name[0]
                        }}</span>
                      </v-avatar>
                    </template>
                    <span>{{ item.user_id_detail.name }}</span>
                  </v-tooltip>

                  <div>
                    <v-card elevation="8" class="ml-2 d-inline-block  rounded-lg rounded-bl-0 outlined" max-width="500px"
                      color="blue-grey lighten-5">
                      <v-card-text class="py-1 px-3">
                        <div class="">{{ item.value }}</div>
                      </v-card-text>
                    </v-card>
                    <div class="ml-4 timeFont line-1">
                      {{ $nova.formatDate(item.created_at, "DD MMM yyyy h:mm A") }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="queryDetails.stage !== 2 && ($nova.hasRight(right, 'edit')|| $nova.hasRight(right, 'add'))">
                <div class="d-flex align-center mt-10">
                  <lb-string label="Query" v-model="queryMsg" hidedetails />
                  <v-btn small @click="sendQuery" color="primary" class="ml-3" :loading="loading">
                    <v-icon class="mr-2">mdi-send</v-icon>Send</v-btn>
                </div>
              </div>
            </div>
            <div v-if="loading && !queryDetails._id">
              <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
            </div>
            <div v-else-if="!queryDetails._id" class="pa-8 title text-center grey--text">
              No Data found
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right: "helpdesk",
      loading: false,
      assUserloading: false,
      querySendloading: false,
      queryMsg: null,
      chatList: [],
      queryDetails: {},
      queryObj: {},
      conversationList: {},
      queriesID: null,
      userList: null,
      assignUser: "",
    };
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = true;
      this.$route.params.QueryId
        ? (this.queriesID = this.$route.params.QueryId)
        : (this.queriesID = this.$route.params.QueryHelpDeskId);
      this.assignUser = "";
      this.axios.post("/v2/helpdesk/queries/getallusers").then((dt) => {
        if (dt.data.status === "success") {
          this.userList = dt.data.data;
          return this.axios.post("/v2/helpdesk/queries/get/" + this.queriesID);
        }else throw Error("Error getting user list");
      }).then(dt => {
        if (dt.data.status === "success") {
          this.queryDetails = dt.data.data[0] || {};
          if (this.queryDetails.assign_to) {
            this.assignUser = this.queryDetails.assign_to;
          }
          return this.axios.post("/v2/helpdesk/queries/conversation/get/" + this.queriesID);
        }else throw Error("Error getting query detail");
      }).then(dt => {
        if (dt.data.status === "success") {
          this.conversationList = dt.data.data;
        }else throw Error("Error getting conversations");
      }).catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    },

    sendQuery() {
      this.querySendloading = true;
      this.queryObj = {
        queries_id: this.queriesID,
        value: this.queryMsg,
      };
      this.axios
        .post("/v2/helpdesk/queries/conversation/add", { data: this.queryObj })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.queryMsg = null;
            this.refreshData();
            this.$store.commit("sbSuccess", "query send successfully");
          }
          else {
            this.queryMsg = null;
            this.$store.commit("sbError", "Query cannot be empty");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.querySendloading = false;
        });
    },
    closeQuery() {
      this.loading = true;
      this.axios
        .post("/v2/helpdesk/queries/close/" + this.queriesID)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "Query closed successfully");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openQuery() {
      this.loading = true;
      this.axios
        .post("/v2/helpdesk/queries/open/" + this.queriesID)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "Query closed successfully");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addAssignUser() {
      this.assUserloading = true;
      let UserObject = { assign_to: this.assignUser };
      this.axios
        .post("/v2/helpdesk/queries/assignuser/" + this.queriesID, {
          data: UserObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "User Assigned successfully");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.assUserloading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeFont {
  font-size: 9px !important;
}
</style>